import { useMutation, useQuery } from '@apollo/client';
import {
  ArrowBackIos,
  Check,
  Close,
  LocalAtm,
  OfflinePinOutlined,
  PanToolOutlined,
  Tour,
  VerifiedOutlined,
} from '@mui/icons-material';
import { Grid, Skeleton } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { PATHS } from '../../constants';
import { useAuth } from '../../helpers/AuthenticationProvider';
import { GET_USER_NAME } from '../../queries/authentication';
import {
  CHANGE_STATE_BY_CODE,
  GET_BOOKING_HISTORY_BY_CODE,
  GET_BOOKING_PAYMENT_BY_CODE,
} from '../../queries/bookings';
import fontsStyles from '../../styles/fontStyles';
import styles from './styles';

const getState = (status) => {
  switch (status) {
    case 1:
      return 'Pending';
    case 2:
      return 'Confirmed';
    case 3:
      return 'Cancelled';
    case 4:
      return 'Completed';
    case 5:
      return 'Expired';
    case 6:
      return 'In Progress';
    case 7:
      return 'On Hold';
    case 8:
      return 'Rejected';
    case 9:
      return 'Awaiting Payment';
    case 10:
      return 'Refunded';
    case 11:
      return 'Partially Fulfilled';
    case 12:
      return 'No Show';
    default:
      return 'Pending';
  }
};

const BookingDetailsPage = () => {
  const classes = styles();
  const navigate = useNavigate();
  const location = useLocation();
  const fontClasses = fontsStyles();
  const [locationState, setLocationState] = useState(undefined);
  const { booking } = location?.state || { booking: null };
  const [pendingState, setPendingState] = useState(location?.bookingStatus || '');
  const [user, setUser] = useState('');
  const { userId } = useAuth();

  const { data: userNameResult } = useQuery(GET_USER_NAME, {
    variables: {
      usersPermissionsUserId: userId,
    },
  });

  const {
    data: bookingHistory,
    loading: loadingHistory,
    refetch: historyRefetch,
  } = useQuery(GET_BOOKING_HISTORY_BY_CODE, {
    variables: {
      filters: {
        booking_id: {
          eq: booking?.id || '',
        },
      },
    },
  });

  const { data: payDetails, loading: loadingDetails } = useQuery(GET_BOOKING_PAYMENT_BY_CODE, {
    variables: {
      filters: {
        booking_id: {
          id: {
            eq: booking?.id || '',
          },
        },
      },
    },
  });

  useEffect(() => {
    if (userNameResult && userNameResult?.usersPermissionsUser?.data?.attributes) {
      const username = userNameResult.usersPermissionsUser.data.attributes.name;
      setUser(username);
    }
  }, [userNameResult]);

  const [changeState] = useMutation(CHANGE_STATE_BY_CODE, {
    onError: () => {
      console.log('State change error');
    },
    onCompleted: () => {
      const currentBooking = structuredClone(locationState);
      currentBooking.bookingStatus = pendingState;
      navigate({}, { state: { booking: currentBooking }, replace: true });
      setLocationState(currentBooking);
      historyRefetch();
    },
  });

  useEffect(() => {
    if (!booking) {
      navigate(PATHS.bookings); // No tiene state entonces lo devuelve
    } else {
      setLocationState(booking);
    }
  }, [booking]);

  const buttonBuilder = (status) => {
    switch (status) {
      case 'In Progress':
        return {
          color: 'yellow',
          buttons: [
            { text: 'Complete', icon: <VerifiedOutlined />, class: classes.filledButton, state: 4 },
            { text: 'Hold On', icon: <PanToolOutlined />, class: classes.whiteButton, state: 7 },
            {
              text: 'Partially Completed',
              icon: <OfflinePinOutlined />,
              class: classes.whiteButton,
              state: 11,
            },
          ],
        };
      case 'Confirmed':
        return {
          color: 'gray',
          buttons: [
            { text: 'Start', icon: <Tour />, class: classes.filledButton, state: 6 },
            { text: 'Cancel', icon: <Close />, class: classes.whiteButton, state: 3 },
            { text: 'Refund', icon: <LocalAtm />, class: classes.whiteButton, state: 10 },
            { text: 'Absent', icon: <Close />, class: classes.whiteButton, state: 12 },
          ],
        };
      case 'On Hold':
        return {
          color: 'yellow',
          buttons: [{ text: 'Start', icon: <Tour />, class: classes.filledButton, state: 6 }],
        };
      case 'Awaiting Payment':
        return {
          color: 'yellow',
          buttons: [{ text: 'Confirm', icon: <Check />, class: classes.filledButton, state: 2 }],
        };
      case 'Partially Fulfilled':
        return {
          color: 'yellow',
          buttons: [
            { text: 'Complete', icon: <VerifiedOutlined />, class: classes.filledButton, state: 4 },
          ],
        };
      case 'Pending':
        return {
          color: 'gray',
          buttons: [],
        };
      case 'Cancelled':
        return {
          color: 'red',
          buttons: [],
        };
      case 'Completed':
        return {
          color: 'green',
          buttons: [],
        };
      case 'Expired':
        return {
          color: 'red',
          buttons: [],
        };

      case 'Rejected':
        return {
          color: 'red',
          buttons: [],
        };

      case 'Refunded':
        return {
          color: 'green',
          buttons: [],
        };

      case 'No Show':
        return {
          color: 'red',
          buttons: [],
        };

      default:
        return {
          color: '',
          buttons: [
            { text: 'Confirm', icon: <Check />, class: classes.filledButton },
            { text: 'Cancel', icon: <Close />, class: classes.whiteButton },
          ],
        };
    }
  };

  const changeBookingState = (newState) => {
    changeState({
      variables: {
        updateBookingId: booking?.id || '',
        data: {
          booking_status: newState,
          custom_updated_by: user || 'System',
        },
      },
    });
    setPendingState(getState(newState));
  };

  const pricingInfo = useMemo(() => {
    if (locationState) {
      const priceData = [
        { name: 'Tour Price', value: `$${locationState.tourPrice}` },
        { name: 'Quantity', value: locationState.quantity },
        { name: 'Subtotal', value: `$${locationState.subTotal}` },
        { name: 'Service fee', value: `$${locationState.serviceFee}` },
        { name: 'Total', value: `$${locationState.total}` },
      ];
      return priceData;
    } else {
      return [];
    }
  }, [locationState]);

  const paymentDetailsData = useMemo(() => {
    if (locationState) {
      const isCash = locationState.paymentType === 'Cash';
      const cashData = [{ details: 'Payment Type', value: locationState.paymentType }];
      const details = payDetails?.payments?.data[0]?.attributes;
      const cardData = details
        ? cashData.concat([
            {
              details: 'Description',
              value: details.description,
            },
            { details: 'Cancel', value: details.cancel },
            { details: 'Card Brand', value: details.brand || 'Unavailable' },
            { details: 'Last digits', value: details.last_digits || 'Unavailable' },
            { details: 'Authorization', value: details.authorization || 'Unavailable' },
            { details: 'Tilopay transaction', value: details.tpt || 'Unavailable' },
          ])
        : cashData;
      return isCash ? cashData : cardData;
    } else {
      return [];
    }
  }, [locationState, payDetails]);

  const bookingHistoryData = useMemo(() => {
    if (bookingHistory?.bookingStatusLogs?.data) {
      const history = bookingHistory.bookingStatusLogs.data;
      const res = history.map((log) => {
        const date = moment(log.attributes.updatedAt);
        return {
          status: log.attributes.current_status,
          updatedAt: date.format('YYYY-MM-DD hh:mm A'),
          updatedBy: log.attributes.custom_updated_by,
        };
      });
      return res;
    } else {
      return [];
    }
  }, [bookingHistory]);

  return (
    <div>
      <Grid container className={classes.mainWrapper}>
        {locationState && (
          <Grid item sm={12} xs={12} md={12} lg={12} xl={10}>
            <div className={classes.section}>
              <div className={classes.titleContainer}>
                <Link
                  to={PATHS.bookings}
                  aria-label='toggle password visibility'
                  className={classes.backIcon}
                >
                  <ArrowBackIos className={classes.svg} />
                </Link>
                <h1 className={fontClasses.h3}>Booking details</h1>
              </div>
              <div className={`${classes.detailSectionContainer} booking`}>
                <p
                  className={classes.bookingSectionTitle}
                >{`Code: ${locationState.bookingCode}`}</p>
                <div
                  className={`${classes.statusPill} ${buttonBuilder(locationState.bookingStatus).color}`}
                >
                  {locationState.bookingStatus}
                </div>
                <div className={classes.bookingDataContainer}>
                  <div className={classes.bookingDataRow}>
                    <p className={classes.bookingDataTitle}>Created</p>
                    <p className={classes.bookingDataDetail}>
                      {moment(locationState.createdAt).format('YYYY-MM-DD hh:mm A')}
                    </p>
                  </div>
                  <Grid container spacing={'10px'}>
                    <Grid item xs={6} md={3}>
                      <p className={classes.bookingDataTitle}>Name</p>
                      <p className={classes.bookingDataDetail}>{locationState.client}</p>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <p className={classes.bookingDataTitle}>Phone</p>
                      <p className={classes.bookingDataDetail}>{locationState.userPhone}</p>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <p className={classes.bookingDataTitle}>Email</p>
                      <p className={classes.bookingDataDetail}>{locationState.userEmail}</p>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <p className={classes.bookingDataTitle}>Cruise</p>
                      <p className={classes.bookingDataDetail}>{locationState.cruise}</p>
                    </Grid>
                  </Grid>
                  <Grid container spacing={'10px'}>
                    <Grid item xs={6}>
                      <p className={classes.bookingDataTitle}>Tour</p>
                      <p className={classes.bookingDataDetail}>{locationState.tourName}</p>
                    </Grid>
                    <Grid item xs={3}>
                      <p className={classes.bookingDataTitle}>Quantity</p>
                      <p
                        className={classes.bookingDataDetail}
                      >{`${locationState.quantity} ${locationState.quantity === 1 ? 'person' : 'people'}`}</p>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <p className={classes.bookingDataTitle}>Tour Date</p>
                      <p className={classes.bookingDataDetail}>{locationState.bookingDate}</p>
                    </Grid>
                  </Grid>
                </div>
                {buttonBuilder(locationState.bookingStatus).buttons.length > 0 && (
                  <div className={classes.bookingDataButtonContainer}>
                    {buttonBuilder(locationState.bookingStatus).buttons.map((button, index) => (
                      <button
                        key={`data-sec-1-${button.text}-${index}`}
                        className={button.class}
                        onClick={() => changeBookingState(button.state)}
                      >
                        {button.icon}
                        {button.text}
                      </button>
                    ))}
                  </div>
                )}
              </div>
              <div>
                <p className={classes.pageSubtitle}>Payment Details</p>
                <div className={classes.paymentSection}>
                  {!loadingDetails ? (
                    <div className={`${classes.detailSectionContainer} table first`}>
                      <div className={classes.tableTitleContainer}>
                        <Grid container className={classes.tableRowPadding} spacing={'10px'}>
                          <Grid item xs={12}>
                            <p className={classes.tableTitle}>Details</p>
                          </Grid>
                        </Grid>
                      </div>
                      <div className={classes.tableRowsContainer}>
                        {paymentDetailsData.map((detail, index) => (
                          <Grid
                            container
                            key={`data-sec-2-${detail.details}-${index}`}
                            spacing={'10px'}
                          >
                            <Grid item xs={6}>
                              <p className={classes.tableDetailText}>{detail.details}</p>
                            </Grid>
                            <Grid item xs={6}>
                              <p className={classes.tableDetailText}>{detail.value}</p>
                            </Grid>
                          </Grid>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <Skeleton className={`${classes.detailSectionSkeleton} table`} />
                  )}
                  <div className={`${classes.detailSectionContainer} table second`}>
                    <div className={classes.tableTitleContainer}>
                      <Grid container className={classes.tableRowPadding} spacing={'10px'}>
                        <Grid item xs={6}>
                          <p className={classes.tableTitle}>Payment</p>
                        </Grid>
                        <Grid item xs={6}>
                          <p className={classes.tableTitle}>Amount</p>
                        </Grid>
                      </Grid>
                    </div>
                    <div className={classes.tableRowsContainer}>
                      {pricingInfo.map((payment, index) => (
                        <Grid
                          container
                          key={`data-sec-3-${payment.value}-${index}`}
                          spacing={'10px'}
                        >
                          <Grid item xs={6}>
                            <p
                              className={`${classes.tableDetailText} ${index + 1 === pricingInfo.length ? 'bold' : ''}`}
                            >
                              {payment.name}
                            </p>
                          </Grid>
                          <Grid item xs={6}>
                            <p
                              className={`${classes.tableDetailText} ${index + 1 === pricingInfo.length ? 'bold' : ''}`}
                            >
                              {payment.value}
                            </p>
                          </Grid>
                        </Grid>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              {(bookingHistoryData.length > 0 || loadingHistory) &&
                (loadingHistory ? (
                  <>
                    <Skeleton variant='text' className={classes.historyTitleSkeleton} />
                    <Skeleton variant='rectangular' className={classes.historyContentSkeleton} />
                  </>
                ) : (
                  <div>
                    <p className={classes.pageSubtitle}>Booking History</p>
                    <div className={`${classes.detailSectionContainer} table`}>
                      <div className={classes.tableTitleContainer}>
                        <Grid container className={classes.tableRowPadding} spacing={'10px'}>
                          <Grid item xs={4}>
                            <p className={classes.tableTitle}>Status</p>
                          </Grid>
                          <Grid item xs={4}>
                            <p className={classes.tableTitle}>Updated at</p>
                          </Grid>
                          <Grid item xs={4}>
                            <p className={classes.tableTitle}>Updated by</p>
                          </Grid>
                        </Grid>
                      </div>
                      <div className={classes.tableRowsContainer}>
                        {bookingHistoryData.map((history, index) => (
                          <Grid
                            container
                            key={`data-sec-4-${history.updateAt}-${index}`}
                            spacing={'10px'}
                          >
                            <Grid item xs={4}>
                              <p className={classes.tableDetailText}>{history.status}</p>
                            </Grid>
                            <Grid item xs={4}>
                              <p className={classes.tableDetailText}>{history.updatedAt}</p>
                            </Grid>
                            <Grid item xs={4}>
                              <p className={classes.tableDetailText}>{history.updatedBy}</p>
                            </Grid>
                          </Grid>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default BookingDetailsPage;
